// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-em-redor-mdx": () => import("./../../../src/pages/em-redor.mdx" /* webpackChunkName: "component---src-pages-em-redor-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicos-mdx": () => import("./../../../src/pages/servicos.mdx" /* webpackChunkName: "component---src-pages-servicos-mdx" */),
  "component---src-pages-sobre-nos-mdx": () => import("./../../../src/pages/sobre-nos.mdx" /* webpackChunkName: "component---src-pages-sobre-nos-mdx" */)
}

